.form {
    display: grid;
    grid-row-gap: 24px;
    padding: 16px;
    padding-bottom: 24px;
    background-color: var(--blue-800);
    text-align: left;
    position: relative;
}

.errorMsg {
    color: var(--brown-300);
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
}

.submitted {
    color: var(--brown-300);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: -16px auto;
}

.form_header {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: var(--white);
}

.form>div {
    display: grid;
    text-align: left;
}

label {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--white);
}

input,
textarea {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--grey-600);
    width: 100%;
    height: 56px;
    padding: 16px;
    border: 0.5px solid var(--grey-400);
    outline: none;
}

input:focus,
textarea:focus {
    border: 1px solid var(--grey-700);
}

textarea {
    height: 144px;
}

input::placeholder,
textarea::placeholder {
    color: var(--grey-200);
}

#popup_message {
    display: block;
    opacity: 0;
    -webkit-animation: fadein 5s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 5s;
    /* Firefox < 16 */
    -ms-animation: fadein 5s;
    /* Internet Explorer */
    -o-animation: fadein 5s;
    /* Opera < 12.1 */
    animation: fadein 5s;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0.5;
    }

    20% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}


#popup_message {
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
}

.popup_message {
    margin: 0 auto;
    background-color: var(--grey-500);
    border: 0.5px solid var(--grey-700);
    border-radius: 15px;
    width: fit-content;
}

.popup_message p {
    padding: 16px 24px;
    color: var(--brown-300);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    width: 100%;
    max-width: 280px;
}

@media (min-width: 576px) {
    .form {
        padding: 24px;
        padding-bottom: 40px;
    }

    .form_header {
        font-size: 32px;
        line-height: 38px;
    }

    .form .btn {
        margin-top: 16px;
    }

    .popup_message p {
        max-width: 375px;
    }
}

@media (min-width: 1024px) {
    .form {
        padding: 32px;
        padding-bottom: 50px;
    }

    .popup_message p {
        max-width: 493px;
    }
}