.services,
.services_content {
    text-align: left;
    margin-bottom: 80px;
}

.grid {
    text-align: left;
}

.grids .text {
    margin-bottom: 24px;
}

.grid-image {
    width: 100%;
}

.service .grid-image {
    width: 100%;
}

.grid_smallText {
    color: var(--blue-500);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
}

.grid_title {
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: var(--grey-800);
}

.grid_subtitle {
    width: 100%;
    max-width: 525px;
    margin-top: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--grey-600);
}

.service .gridImage {
    display: grid;
}

.service .grid-image {
    grid-area: 1/2;
    grid-column-start: revert;
    margin-bottom: 40px;
}


@media (min-width: 576px) {
    .grids .text {
        margin-bottom: 40px;
    }

    .grid_title {
        max-width: 405px;
        font-size: 32px;
        line-height: 38px;
    }

    .grid_subtitle {
        font-size: 16px;
        line-height: 24px;
    }

}

@media (min-width:1024px) {

    .split_grid .grids {
        display: flex;
        justify-content: space-between;
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 24px;
        text-align: left;
    }

    .service .gridImage {
        grid-column-gap: 64px;
        grid-template-columns: 5fr 4fr;
    }

    .grids .text {
        margin-bottom: 0px;
    }

    .gridImage {
        align-items: center;
    }

    .grid-image {
        height: 520px;
        max-width: 468px;
    }

    .service .grid-image {
        margin-bottom: 0;
        height: 480px;
    }

    .grid_title {
        margin-top: 0;
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -1px;
    }

    .grid_subtitle {
        margin-top: 24px;
        font-size: 20px;
        line-height: 28px;
    }
}

@media (min-width: 1280px) {

    .grid {
        grid-column-gap: 32px;
    }

    .grid-image,
    .service .grid-image {
        max-width: 560px;
        height: 547px;
    }

    .grid_title {
        max-width: 504px;
        font-size: 44px;
        line-height: 49px;
    }

}