@font-face {
font-family: 'Helvetica Neue';
src: url('../fonts/HelveticaNeue-Bold.otf') format('otf');
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'Helvetica Neue';
src: url('../fonts/HelveticaNeue-Regular.otf') format('otf');
font-weight: 400;
font-style: normal;

}

@font-face {
font-family: 'Helvetica Neue';
src: url('../fonts/HelveticaNeue-Medium.otf') format('otf');
font-weight: 500;
font-style: normal;

}