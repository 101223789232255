/* Button Sizes */
.btn {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    width: 100%;
    height: 56px;
}



.brown_btn {
    background-color: var(--brown-300);
    border: 1px solid var(--brown-300);
    color: var(--grey-900);
}

.btn.brown_btn:focus,
.btn.brown_btn:hover {
    background-color: var(--brown-400);
    border: 1px solid var(--brown-400);
    color: var(--grey-900);
}

.btn.brown:focus,
.btn.brown:hover {
    background-color: var(--brown-400);
    border: 1px solid var(--brown-400);
}

.blue_btn {
    border: 1px solid var(--blue-500);
    background-color: var(--blue-500);
    color: var(--white);
}

.btn.blue_btn:focus,
.btn.blue_btn:hover {
    border: 1px solid var(--blue-600);
    background-color: var(--blue-600);
    color: var(--white);
}

.black_btn {
    background-color: var(--brown-300);
    border: 1px solid var(--brown-300);
    color: var(--grey-900);
}



.small_btn {
    width: 218px;
    height: 40px;
    padding: 8px 8px 8px 16px;
}

.big_btn {
    width: 255px;
    height: 48px;
    padding: 13px;
}

.footer-btn {
    width: 225px;
    height: 48px;
    padding: 13px;
}

.imgSize {
    width: 24px;
    height: 24px;
}

.buttons {
    padding-top: 0 !important;
}